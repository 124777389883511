<template>
	<div class="jobadd">
		<div class="jobadd-title">
			{{type=='edit'?'岗位编辑':'岗位新增'}}
		</div>
		<div class="jobadd-tab" v-if="type=='add'">
			<span @click="changeType(item.type)" v-for="(item, index) in jobtypeList" :key="index"
				:class="{'tab-item-active': item.type == currentType}" 
				class="jobadd-tab-item">{{item.title}}</span>
		</div>
		<div class="jobadd-tab" v-if="type=='edit'">
			<span v-for="(item, index) in jobtypeList" :key="index">
				<span @click="changeType(item.type)" 
				v-if="item.type == currentType"
				class="jobadd-tab-item tab-item-active" >
				<span >{{item.title}}</span>
				</span>
			</span>
			
		</div>
		<el-form ref="formJob" :model="formJob" :rules="rules">
			<el-form-item prop="schoolId">
				<div class="jobadd-formitem">
					<span class="jobadd-formitem-label">校区</span>
					<el-select class="jobadd-formitem-val"
						placeholder="请选择招募校区" v-model="formJob.schoolId">
						<el-option  v-for="(item, index) in schoolList" :key="index" :label="item.name"
							:value="item.id">
						</el-option>
					</el-select>
				</div>
			</el-form-item>
			<el-form-item prop="name">
				<div class="jobadd-formitem">
					<span class="jobadd-formitem-label">岗位名称</span>
					<el-input placeholder="请输入岗位名称" class="jobadd-formitem-val" type="text" v-model="formJob.name">
					</el-input>
				</div>
			</el-form-item>
			<el-form-item prop="starTime" v-if="currentType == 'S_FIX'||currentType == 'C_FIX'">
				<!-- <div > -->
				<!-- <div v-if="currentType != 'C_FIX'" class="jobadd-formitem"> -->
					<div  class="jobadd-formitem" v-if="currentType == 'S_FIX'">
						<span class="jobadd-formitem-label">岗位时间</span>
						<el-date-picker class="jobadd-formitem-val" format="yyyy-MM-dd"
							value-format="yyyy-MM-dd" type="date" placeholder="请选择开始时间"
							:disabled="type==='edit'"
							:class="{'bg':type=='edit'}"
							v-model="formJob.starTime">
						</el-date-picker>
						<span style="margin: 0 10px;">至</span>
						<el-date-picker style="border-left: 1px solid #DCDFE6;" class="jobadd-formitem-val"
						:class="{'bg':type=='edit'}"
							format="yyyy-MM-dd" value-format="yyyy-MM-dd" type="date" placeholder="请选择结束时间"
							:disabled="type==='edit'"
							v-model="formJob.endTime">
						</el-date-picker>
					</div>
					
				<!-- </div> -->
				<!-- </div> -->
				<div v-else class="jobadd-formitem">
					<span class="jobadd-formitem-label">岗位时间</span>
					<el-date-picker @change="startDateChange" class="jobadd-formitem-val" format="yyyy-MM-dd"
					:disabled="type==='edit'"
					:class="{'bg':type=='edit'}"
						value-format="yyyy-MM-dd" type="date" placeholder="请选择开始时间" v-model="formJob.starTime">
					</el-date-picker>
					<span style="margin: 0 10px;">至</span>
					<el-date-picker @change="endDateChange" style="border-left: 1px solid #DCDFE6;"
					:disabled="type==='edit'"
					:class="{'bg':type=='edit'}"
						class="jobadd-formitem-val" format="yyyy-MM-dd" value-format="yyyy-MM-dd" type="date"
						placeholder="请选择结束时间" v-model="formJob.endTime">
					</el-date-picker>
				</div>
			</el-form-item>
			<el-form-item prop="postTime" v-if="currentType !== 'S_FIX'&&currentType !== 'C_FIX'">
				<div class="jobadd-formitem">
					<span class="jobadd-formitem-label">岗位时间</span>
					<el-input placeholder="请输入岗位时间" class="jobadd-formitem-val" type="text" v-model="formJob.postTime">
					</el-input>
				</div>
			</el-form-item>
			<div v-if="currentType == 'S_FIX'||currentType == 'C_FIX'">
				<span class="time-note">注：请正确输入岗位时间，发布之后无法再重新修改</span>
			</div>
			<el-form-item prop="address">
				<div class="jobadd-formitem">
					<span class="jobadd-formitem-label">岗位地址</span>
					<el-input placeholder="请输入岗位地址" class="jobadd-formitem-val" type="text" v-model="formJob.address">
					</el-input>
				</div>
			</el-form-item>
			<el-form-item prop="maxPeo">
				<div class="jobadd-formitem">
					<span class="jobadd-formitem-label">可报名人数</span>
					<el-input placeholder="请输入岗位可报名人数数量（限数字）" class="jobadd-formitem-val" type="text"
						v-model="formJob.maxPeo"></el-input>
				</div>
			</el-form-item>
			<el-form-item prop="admittedNum">
				<div class="jobadd-formitem">
					<span class="jobadd-formitem-label">在岗人数</span>
					<el-input placeholder="请输入岗位在岗人数数量（限数字）" class="jobadd-formitem-val" type="text"
						v-model="formJob.admittedNum"></el-input>
				</div>
			</el-form-item>
			<el-form-item prop="salary">
				<div class="jobadd-formitem">
					<span class="jobadd-formitem-label">每人工资</span>
					<el-input placeholder="请输入每人工资" class="jobadd-formitem-val" type="text" v-model="formJob.salary">
					</el-input>
				</div>
			</el-form-item>
			<el-form-item prop="contact" v-if="currentType !== 'SO_FIX_TUTOR'&&currentType!=='SO_PART_TIME'">
				<div class="jobadd-formitem">
					<span class="jobadd-formitem-label">联系人</span>
					<el-input placeholder="请输入联系人" class="jobadd-formitem-val" type="text" v-model="formJob.contact">
					</el-input>
				</div>
			</el-form-item>
			<el-form-item prop="contactPhone" v-if="currentType !== 'SO_FIX_TUTOR'&&currentType!=='SO_PART_TIME'">
				<div class="jobadd-formitem">
					<span class="jobadd-formitem-label">联系方式</span>
					<el-input placeholder="请输入手机号/微信号/qq号" class="jobadd-formitem-val" type="text"
						v-model="formJob.contactPhone"></el-input>
				</div>
			</el-form-item>
			<el-form-item prop="details">
				<div class="jobadd-detail">
					<div class="jobadd-detail-label">岗位详情</div>
					<el-input type="textarea" class="jobadd-detail-val" :autosize="{ minRows: 6, maxRows: 6}"
						:placeholder="currentType === 'S_FIX'||currentType ==='S_TEMP'?'请输入岗位详情，如岗位基本情况、必要提示等':'请输入岗位详情，如岗位基本情况、必要提示、负责人姓名、负责人联系方式等'" v-model="formJob.details"></el-input>
					<!-- <textarea class="jobadd-detail-val" placeholder="请输入岗位详情" v-model="formJob.details"/> -->
				</div>
			</el-form-item>
			<el-form-item prop="postDuty" v-if="currentType == 'SO_FIX_TUTOR'||currentType=='SO_PART_TIME'">
				<div class="jobadd-detail">
					<div class="jobadd-detail-label">岗位要求</div>
					<el-input type="textarea" class="jobadd-detail-val" :autosize="{ minRows: 6, maxRows: 6}"
						placeholder="请输入岗位要求" v-model="formJob.postDuty"></el-input>
					<!-- <textarea class="jobadd-detail-val" placeholder="请输入岗位要求" v-model="formJob.postDuty"/> -->
				</div>
			</el-form-item>
			<el-form-item prop="orgId" v-if="currentType == 'S_FIX'||currentType == 'S_TEMP'">
				<div class="jobadd-formitem">
					<span class="jobadd-formitem-label">所属部门</span>
					<el-select class="jobadd-formitem-val"
						placeholder="请选择所属部门" v-model="formJob.orgId">
						<el-option v-for="(item, index) in orgList" :key="index" :label="item.name"
							:value="item.id">
						</el-option>
					</el-select>
				</div>
			</el-form-item>
		</el-form>
		<div class="jobadd-btn">
			<span @click="jobHandle('confirm','formJob')" class="jobadd-btn-confirm">{{editId?'确认修改':'立即发布'}}</span>
			<span @click="jobHandle('cancel')" class="jobadd-btn-cancel">取消</span>
		</div>
	</div>
</template>

<script>
	import storage from 'store';
	import {
		addJob,
		getJobDetail,
		editJob,
		schoollistAllPc
	} from '@/api/pc/index'
	import {
		orgListAllPc
	} from '@/api/pc/user'
	export default {
		data() {
			const checkPostTime = (rule, value, callback) => {
				if (!this.formJob.starTime || !this.formJob.endTime) {
					callback(new Error("请选择岗位时间"));
				} else {
					if (this.currentType != 'C_FIX') {
						let start = new Date(this.formJob.starTime).getTime()
						let end = new Date(this.formJob.endTime).getTime()
						if (start >= end) {
							callback(new Error("开始时间必须早于结束时间"));
						} else {
							callback();
						}
						callback();
					}
					callback();
				}
				callback();
			};
			const checkMaxpeo = (rule, value, callback) => {
				if (!value) {
					callback(new Error("请输入可报名人数"));
				} else {
					if (!(/(^[1-9]\d*$)/.test(value))) {
						callback(new Error("请输入正整数"));
					} else {
						callback();
					}
					callback();
				}
				callback();
			}
			const checkAdmittedNum = (rule, value, callback) => {
				if (!value) {
					callback(new Error("请输入在岗人数"));
				} else {
					if (!(/(^[1-9]\d*$)/.test(value))) {
						callback(new Error("请输入正整数"));
					} else {
						callback();
					}
					callback();
				}
				callback();
			}
			return {
				department:[
					{
						label:'部门1',
						value:'1'
					},
					{
						label:'部门2',
						value:'2'
					},
				],
				identityPc: "",
				currentType: undefined,
				jobtypeList: [],
				orgList:[],
				schoolList:[],
				formJob: {
					name: "",
					postType: 1,
				},
				type:'add',
				editId: "",
				rules: {
					schoolId:[{
						required: true,
						message: '请选择招募校区',
						trigger: 'change'
					}, ],
					name: [{
						required: true,
						message: '请输入岗位名称',
						trigger: 'blur'
					}, ],
					starTime: [{
						required: true,
						// message: '请选择岗位时间',
						validator: checkPostTime,
						trigger: 'blur'
					}, ],
					address: [{
						required: true,
						message: '请输入岗位地址',
						trigger: 'blur'
					}, ],
					postTime:[{
						required: true,
						message: '请输入岗位时间',
						trigger: 'blur'
					}, ],
					admittedNum:[{
						required: true,
						// message: '请输入在岗人数',
						validator:checkAdmittedNum,
						trigger: 'blur'
					}, ],
					maxPeo: [{
						required: true,
						// message: '请输入人数',
						validator: checkMaxpeo,
						trigger: 'blur'
					}, ],
					salary: [{
						required: true,
						message: '请输入工资',
						trigger: 'blur'
					}, ],
					contact: [{
						required: true,
						message: '请输入联系人',
						trigger: 'blur'
					}, ],
					contactPhone: [{
						required: true,
						message: '请输入手机号/微信号/qq号',
						trigger: 'blur'
					}, ],
					details: [{
						required: true,
						message: '请输入岗位详情',
						trigger: 'blur'
					}, ],
					postDuty: [{
						required: true,
						message: '请输入岗位要求',
						trigger: 'blur'
					}, ],
					orgId:[{
						required: true,
						message: "请选择所属部门",
						trigger: "blur"
					}]
				},
			}
		},
		created() {
			if (this.$route.query.id) {
				this.editId = this.$route.query.id
				this.getJobData()
			}
			if(this.$route.query.type){
				this.type=this.$route.query.type
			}
		},
		mounted() {
			this.identityPc = storage.get("identityPc")
			if (this.identityPc == "学院老师") {
				this.jobtypeList = [{
					type: "C_FIX",
					title: "学院固定"
				}, ]
				this.currentType = "C_FIX"
			} else if (this.identityPc == "干部") {
				this.jobtypeList = [{
						type: "S_FIX",
						title: "校内固定"
					},
					{
						type: "S_TEMP",
						title: "校内临时"
					},
					{
						type: "SO_FIX_TUTOR",
						title: "校外家教"
					},
					{
						type: "SO_PART_TIME",
						title: "校外兼职"
					},
				]
				this.currentType = "S_FIX"
			}
			this.formJob.postType = this.currentType
			this.getOrgList()
			this.getSchoolList()
		},
		methods: {
			//获取部门列表
			getOrgList() {
				let params = {
					pageIndex: 1,
					pageSize: 999
				}
				orgListAllPc(params).then((res) => {
					if (res.code == 0) {
						this.orgList = res.data
					}
				})
			},
			//获取校区列表
			getSchoolList() {
				let params = {
					pageIndex: 1,
					pageSize: 999
				}
				schoollistAllPc(params).then((res) => {
					if (res.code == 0) {
						this.schoolList = res.data
					}
				})
			},
			startDateChange(e) {
				if (e) {
					this.formJob.starTime = e
					// this.formJob.starTime = e + "-01"
				} else {
					this.formJob.starTime = ""
				}
				// console.log("startDateChange", this.formJob.starTime)
			},
			endDateChange(e) {
				if (e) {
					this.formJob.endTime = e
					// this.formJob.endTime = e + "-01"
				} else {
					this.formJob.endTime = ""
				}
				// console.log("endDateChange", this.formJob.endTime)
			},
			//获取岗位详情信息
			getJobData() {
				getJobDetail(this.editId).then(res => {
					if (res.code == 0) {
						this.formJob = res.data
						this.currentType = res.data.postType
					}
				})
			},
			changeType(type) {
				this.formJob = {}
				this.currentType = type
				this.formJob.postType = type
				this.$refs.formJob.resetFields();
			},
			jobHandle(type, formJob) {
				switch (type) {
					case "confirm":
						this.handleAddJob(formJob)
						break;
					case "cancel":
						this.$router.go(-1)
						break;
				}
			},
			handleAddJob(formJob) {
				this.$refs[formJob].validate((valid) => {
					if (valid) {
						if (this.editId) {
							editJob(this.formJob, this.editId).then(res => {
								if (res.code == 0) {
									this.$router.go(-1)
								}
							})
						} else {
							console.log(this.formJob)
							addJob(this.formJob).then(res => {
								if (res.code == 0) {
									this.$router.go(-1)
								}
							})
						}
					} else {

					}
				});

			},
		}
	}
</script>

<style lang="scss" scoped>
	.gray_bg{
		border-color:#F5F7FA;
		border-color: #E4E7ED;
		color: #C0C4CC;
	}
	/deep/.el-input__inner {
		height: 30px;
		border-color: #DCDFE6;
		border-radius: 0px 2px 2px 0px;
		border-left: 0;
	}

	/deep/.el-form-item {
		margin-bottom: 0;
	}

	/deep/.el-input__inner {
		border: 0;
		line-height:40px;
		font-size:14px;
	}

	/deep/.el-textarea__inner {
		border: 0;
		resize: none;
	}
	/deep/.el-select .el-input .el-select__caret {
		font-size:14px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	// /deep/.el-select{
	// 	font-size:14px;
	// }
	.bg{
		background:#F5F7FA;
	}
	.jobadd {
		display: flex;
		flex-direction: column;
		padding-bottom: 16px;

		.jobadd-title {
			font-size: 16px;
			font-family: PingFang SC;
			font-weight: bold;
			line-height: 22px;
			color: #303133;
			margin-top:16px;
			margin-bottom: 16px;
			padding-left: 10px;
			border-left: 4px solid #4392FA;
		}

		.jobadd-tab {
			display: flex;

			.jobadd-tab-item {
				width: 120px;
				line-height: 36px;
				border: 1px solid #DCDFE6;
				border-radius: 2px;
				display: inline-block;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 14px;
				font-family: PingFang SC;
				font-weight: 400;
				color: #606266;
				cursor: pointer;
				margin-right: 16px;
			}

			.tab-item-active {
				background: rgba(67, 146, 250, 0.1000);
				border: 1px solid #4392FA;
				color: #4392FA;
			}
		}

		.jobadd-formitem {
			display: flex;
			align-items: center;
			margin-top: 16px;

			.jobadd-formitem-label {
				min-width: 120px;
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 0 22px;
				box-sizing: border-box;
				font-size: 14px;
				font-family: PingFang SC;
				font-weight: 400;
				color: #606266;
				border-radius: 2px 0px 0px 2px;
				background: #F6F7F9;
				border: 1px solid #DCDFE6;
				line-height: 38px;
				box-sizing: border-box;
			}

			.jobadd-formitem-val {
				flex: 1;
				height: 40px;
				line-height: 40px;
				border: 0;
				outline: none;
				box-sizing: border-box;
				font-size: 14px;
				font-family: PingFang SC;
				font-weight: 400;
				color: #606266;
				border: 1px solid #DCDFE6;
				border-radius: 0px 2px 2px 0px;
				border-left: 0;
				padding: 0 10px;
			}

			.jobadd-formitem-date {
				flex: 1;
			}
			
		}
		.time-note{
			color:#909399;
			font-size:12px;
		}
		.jobadd-detail {
			display: flex;
			flex-direction: column;

			.jobadd-detail-label {
				font-size: 14px;
				font-family: PingFang SC;
				font-weight: 400;
				line-height: 40px;
				color: #606266;
				background: #F6F7F9;
				border: 1px solid #DCDFE6;
				border-radius: 2px 2px 0px 0px;
				margin-top: 16px;
				padding: 0 22px;
				box-sizing: border-box;
			}

			.jobadd-detail-val {
				// height: 140px;
				border: 1px solid #DCDFE6;
				border-radius: 0px 0px 2px 2px;
				resize: none;
				border-top: 0;
				font-size: 14px;
				font-family: PingFang SC;
				font-weight: 400;
				color: #606266;
				box-sizing: border-box;
				// padding: 4px 10px;
			}
		}

		.jobadd-btn {
			display: flex;
			align-items: center;
			margin-top: 32px;

			.jobadd-btn-confirm {
				width: 96px;
				height: 36px;
				background: #4392FA;
				border-radius: 2px;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 14px;
				font-family: PingFang SC;
				font-weight: 400;
				color: #FFFFFF;
				cursor: pointer;
				margin-right: 16px;
			}

			.jobadd-btn-cancel {
				width: 96px;
				height: 36px;
				border-radius: 2px;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 14px;
				font-family: PingFang SC;
				font-weight: 400;
				color: #303133;
				cursor: pointer;
				border: 1px solid #DCDFE6;
			}
		}
	}
</style>
